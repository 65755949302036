import React, {Component} from 'react';
import Slider from "react-slick";
import Modal from "react-responsive-modal"
import './slider.scss';

class SliderHome extends Component {
    state = {
        open: true
    }

    onCloseModal = () => {
        this.setState({open: false});
    };

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className={'slider-wrap position-relative'}>
                <Slider {...settings}>
                    <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/images/background.jpg)'}}>
                        <div className = "slider-image" style = {{backgroundColor: 'rgba(0, 0, 0, 0.3)'}} />
                        </div>
                        <div className="container">
                            <div className="slider-content" style = {{display: 'flex', justifyContent: 'center', alginItems: 'center'}}>
                                <img style={{height:"400px" , width:"400px"}}  src="/assets/images/logo1.svg" alt="image" />
                                {/* <div className="slider-footer text-left">
                                    <h6 className="text-white">opening hours</h6>
                                    <p className="text-white">Mon - Fri: 10am - 7pm   /   Sat: 10am - 5pm   /   Sun: 1pm - 5pm</p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/imagewebp/theme/slide2.webp)'}}>
                        </div>
                        <div className="container">
                            <div className="slider-content">
                                <div className="text-center w-100">
                                    <h2 className="text-white">Smart Living with <br/> 5-star services </h2>
                                    <h5 className="text-white mt-5">Convenient Location – Best Price <br/>
                                        – 2 or 3 Bedroom Apartment.</h5>
                                </div>
                                <div className="slider-footer text-left">
                                    <h6 className="text-white">opening hours</h6>
                                    <p className="text-white">Mon - Fri: 10am - 7pm   /   Sat: 10am - 5pm   /   Sun: 1pm - 5pm</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
                <Modal open={this.state.open} onClose={this.onCloseModal} showCloseIcon = {false} center >
                    <div className = "homepage-popup-container">
                        <p className = "homepage-popup-text">Indien u onder meer aan de volgende voorwaarden voldoet, kan u recht hebben op het <strong>verlaagd BTW-tarief van 6%</strong>, anders blijft het BTW-tarief van 21% gelden</p>
                        <button type = "button" className = "homepage-popup-button" onClick = {this.onCloseModal}>
                            Sluiten
                        </button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default SliderHome