import React, {Component} from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Textarea from 'react-validation/build/textarea'
import {isEmail, isEmpty, isMobilePhone} from 'validator';
import './contact-form.scss';
import emailjs from 'emailjs-com';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        console.log("props",props)
        this.state = {
            name: '',
            lastname: '',
            phone: '',
            email: '',
            content: '',
            sended : false,
            sendError : ''
        }

    }
    required = (value) => {
        if (isEmpty(value)) {
            return <small className="form-text text-danger">Dit veld is verplicht</small>;
        }
    }

    email = (value) => {
        if (!isEmail(value)) {
            return <small className="form-text text-danger">Ongeldige email formaat</small>;
        }
    }

    phone = (value) => {
        if (!isMobilePhone(value)) {
            return <small className="form-text text-danger">Ongeldige indeling telefoonnummer</small>;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.formData.validateAll();
        if (this.checkBtn.context._errors.length === 0) {
 
            emailjs.sendForm('service_spakysc', 'template_nzsdvec', '#'+this.props.id, 'user_tBiGjZnJG6nuNWTMcJagn')
            .then((result) => {
                this.setState({
                    sended: true
                });    
            }, (error) => {
                this.setState({
                    sendError: error.text
                });    
            });
        }
    }

    render() {
        return (
            <Form className="form-group-v1" id={this.props.id} onSubmit={e => this.onSubmit(e)} ref={(c) => {
                this.formData = c
            }}>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="name" onChange={(e) => {this.setState({name: e.target.value,sended:false})}} value={this.state.name} type="text" placeholder="Naam *" className="form-control" validations={[this.required]}/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="lastname" onChange={(e) => {this.setState({lastname: e.target.value,sended:false})}} value={this.state.lastname} type="text" placeholder="Voornaam *" className="form-control" validations={[this.required]}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="email" onChange={(e) => {this.setState({email: e.target.value,sended:false})}} value={this.state.email} type="email" placeholder="Email *" className="form-control" validations={[this.required, this.email]}/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="phone" onChange={(e) => {this.setState({phone: e.target.value,sended:false})}}  value={this.state.phone} type="text" placeholder="Telefoon *" className="form-control" validations={[this.required, this.phone]}/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <Textarea name="message" onChange={(e) => {this.setState({content: e.target.value,sended:false})}}  value={this.state.content} className="form-control" placeholder="Uw bericht" rows="3" validations={[this.required]}/>
                </div>
                {this.state.sendError !== '' ? <small className="form-text text-danger">{this.state.sendError}</small> : null} 
                {this.state.sended ? <small className="form-text text-success">Je bericht is verzonden</small> : <button type="submit" className="btn btn-primary mt-4 btn-block">VERSTUREN</button>} 
                <CheckButton style={{display: 'none'}} ref={c => {
                    this.checkBtn = c
                }}/>
            </Form>
        )
    }
}

export default ContactForm