import React, {Component} from 'react';

class Copyright extends Component {
    render (){

        return (
            <div className="copyright">
                <p style = {{textDecoration: 'underline'}}>{this.props.message}</p>
            </div>
        )
    }
}

export default Copyright