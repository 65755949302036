import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Heading from "../../common/heading/heading";

class GallerrySection extends Component {
    state = {
        isOpen: false
    }

    open(e, image) {
        e.preventDefault()
        this.image = image
        this.setState({isOpen: true})
    }

    render() {
        const {flexRow} = this.props;
        return (
            <>
                <Heading>Galerij</Heading>
                <div className="gallerry-wrap p-0">
                    <div className={`row${this.props.flexRow ? ' flex-row-reverse' : ''}`}>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div  className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery5.jpg')}>
                                    <img style={{ height:"180px" }} className="img-fluid" src="/assets/imagewebp/building/gallery5.jpg
                                    " alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery3.jpg')}>
                                    <img className="img-fluid" src="/assets/imagewebp/building/gallery3.jpg" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery4.jpg')}>
                                    <img className="img-fluid" src="/assets/imagewebp/building/gallery4.jpg" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery2.jpg')}>
                                    <img style={{ height:"175px", width: '100%' }} className="img-fluid" src="/assets/imagewebp/building/gallery2.jpg" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery1.jpg')}>
                                    <img style={{height:"375px"}} className="img-fluid" src="/assets/imagewebp/building/gallery1.jpg" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery5.jpg')}>
                                    <img style={{height:"180px"}} className="img-fluid" src="/assets/imagewebp/building/gallery5.jpg" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/imagewebp/building/gallery3.jpg')}>
                                    <img className="img-fluid" src="/assets/imagewebp/building/gallery3.jpg" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    this.state.isOpen &&
                    <Lightbox onCloseRequest={() => this.setState({isOpen: false})} mainSrc={this.image}/>
                }
            </>
        )
    }
}

export default GallerrySection