import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {IntlActions} from 'react-redux-multilingual'

// Import custom components
import MainMenu from "./common/navbar";
import LogoImage from "./common/logo";
import {connect} from "react-redux";

import LanguageSwitchBlock from "./common/language-switch";
import Modal from 'react-responsive-modal';
import ContactForm from "../contact-form/contact-form";


class HeaderOne extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }
    
    componentDidMount() {
        
    }

    openSearch = (e) => {
        e.preventDefault();
        document.querySelector(".popup-search").style.display = "block";
    }


    onOpenModal = (e) => {
        e.preventDefault();
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {
        return (
            <header  id="masthead" className="site-header">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center   justify-content-between w-auto" style={{position:"relative", top:"0"}}>
                            <div className="site-branding">
                                <LogoImage logo={this.props.logoName}/>
                            </div>
                            <div style={{}} >
                            <MainMenu menu={this.props.menu}/>
                            </div>

                            <div className="header-group">
                                <button style={{}}  type="button" className="btn btn-outline-primary" onClick={this.onOpenModal} >Neem Contact</button>
                                <div className="rpm-trigger header-button" id="rpm-trigger">
                                    <i className={`opal-icon opal-icon-toggle`}></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <h6 className="heading-title">Neem Contact</h6>
                                <ContactForm id="contactHeader"/>
                            </div>
                        </div>
                    </div>
                </Modal>
            </header>
        )
    }
}

export default HeaderOne