import React, {Component} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import './ourvalues.scss';
import Heading from "../../common/heading/heading";

import Modal from 'react-responsive-modal';
import ContactForm from "../../common/contact-form/contact-form";

class ApartmentsPlansSection extends Component {
    state = {
        open: false,
        isLoading: true,
        activeTab: 0,
        selected: 1
    }
    data = []

    constructor(props){
        super(props);
        this.load()
    }

    onCloseModal = () => {
        this.setState({open: false});
    };

    load = () => {
        fetch('/api/our-values.json')
            .then((response) => response.json())
            .then((res) => {
                this.data = res;
                this.setState({isLoading: false})
            })
    }

    render() {
        return (
            <div className="container" id="APARTEMENTEN">
                <Heading>Appartementen</Heading>
                <div className={`tabs-wrap`}>
                   <div className = "col-md-6" style = {{padding: '0px'}}>
                        {!this.state.isLoading && (
                            <Tabs>
                                <div>
                                    <TabList>
                                        {this.data.map((data, index) =>
                                            <Tab key={index} onClick = {() => this.setState({activeTab: index, selected: 1})}>{data.title}</Tab>
                                        )}
                                    </TabList>
                                </div>
                                {this.data.map((data, index) =>
                                    <TabPanel key={index}>
                                        <div className="row" style = {{alignItems: 'flex-start'}}>
                                            <div className="col-md-12" >
                                                <div className = "appartment-card-container">
                                                    {data.cards.map((card, cardIndex) => (
                                                        <div className = "react-tabs-card react-tabs-card-animation" style = {{backgroundColor: this.state.selected === (cardIndex + 1) ? '#cabe9f' : '#f8f8f8'}} onClick = {() => this.setState({selected: cardIndex + 1})}>
                                                            <div className = "react-tabs-card-top">
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} className = "react-tabs-card-animation">{card.title}</p>
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#cabe9f'}} className = "react-tabs-card-animation">{card.price}</p>
                                                            </div>
                                                            <div className = "react-tabs-card-bottom">
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} className = "react-tabs-card-animation">Slaapkamers</p>
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} className = "react-tabs-card-animation">{card.slaapkamers}</p>
                                                                <div className = "react-tabs-card-divider react-tabs-card-animation" style = {{backgroundColor: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} />
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} className = "react-tabs-card-animation">Niveau</p>
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} className = "react-tabs-card-animation">{card.niveau}</p>
                                                                <div className = "react-tabs-card-divider react-tabs-card-animation" style = {{backgroundColor: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} />
                                                                <p style = {{color: this.state.selected === (cardIndex + 1) ? 'white' : '#3f4448'}} className = "react-tabs-card-animation">{card.area}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                )}
                            </Tabs>
                        )}
                   </div>
                    <div className="col-md-6" style = {{padding: '0px'}}>
                        <div className="apartment-image">
                            {this.state.selected !== 0 && this.data[this.state.activeTab] && (
                                <>
                                    <img className="img-fluid" src={this.data[this.state.activeTab].cards[this.state.selected - 1].image} alt={this.data[this.state.activeTab].cards[this.state.selected - 1].title}/>
                                    <a href = {this.data[this.state.activeTab].cards[this.state.selected - 1].image} download>
                                        <button type = "button" className = "react-tabs-download">
                                            <p className = "react-tabs-download-text">PLAN DOWNLOADEN</p>
                                        </button>
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <h6 className="heading-title">Schedule a visit</h6>
                                <ContactForm id="contactVisit"/>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className = "col-md-12" style = {{padding: '0px', marginTop: '40px'}}>
                    <p className = "apartment-disclaimer-primary">* Voorbehoud: De vermelde prijzen zijn indicatief en niet bindend. Omwille van de sterk fluctuerende prijzen in de bouwsector kunnen de prijzen op elk ogenblik worden aangepast.  Evenwel zal de koper kunnen genieten van een vaste prijs van zodra een verkoopovereenkomst wordt getekend.</p>
                    <p className = "apartment-disclaimer-primary">(Zijn niet inbegrepen in de prijs: alle belastingen, taksen en heffingen van welke aard ook, de kosten van de Statuten, alle nutsaansluitingen, de verlichtingstoestellen van de  appartementen, het binnenschilderwerk in de privéruimte, de telefooninstallatie, het vaststaande of niet vaststaande meubilair en de decoratiewerken.)</p>
                </div>
            </div>
        )
    }
}

export default ApartmentsPlansSection

