import React, {Component} from 'react';
import {withTranslate} from 'react-redux-multilingual'
import './footer.scss';
import Copyright from "../copyright/copyright";
import ContactForm from "../contact-form/contact-form";


class FooterOne extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {translate} = this.props;
        return (
            <footer className="site-footer">
                {/*<ShapeSVG position={`top`}/>*/}
                {/*Section #1*/}
                <div className="section-1 text-center">
                    <div className="container">
                        <h3 className="text-uppercase">BEN JE GEÏNTERESSEERD?</h3>
                        <h2 className="text-uppercase">HET IS TIJD OM HET PROJECT TE ONTDEKKEN</h2>
                    </div>

                </div>

                {/*Section 2*/}
                <div className="section-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <h6 className="heading-title">LOCATIE GEBOUW</h6>
                                <div className="icon-list footer-address">
                                    <ul>
                                        <li className = "icon-list-margin">
                                            <span className="icon-list-icon">
                                                Adres :
                                            </span>
                                            <span className="icon-list-text">
                                                Mieregemstraat 21 <br></br> 1785 Merchtem
                                            </span>
                                        </li>
                                        <li className = "icon-list-margin">
                                            <span className="icon-list-icon">
                                                Promotor :
                                            </span>
                                            <span className="icon-list-text">
                                                <a href="https://www.gaelensbouwprojecten.be/nl" target="_blank">Gaelensbouwprojecten.be</a>
                                            </span>
                                        </li>
                                        <li className = "icon-list-margin">
                                            <span className="icon-list-icon">
                                                Telefoon :
                                            </span>
                                            <span className="icon-list-text">
                                            +32 9 245 03 33
                                            </span>
                                        </li>
                                        <li className = "icon-list-margin">
                                            <span className="icon-list-icon">
                                                Email :
                                            </span>
                                            <span className="icon-list-text">
                                            <a href="mailto:info@gaelensbouwprojecten.be">info@gaelensbouwprojecten.be</a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <h6 className="heading-title">CONTACT AGENT</h6>
                                <div className="image-box-wrap">
                                    <div className="image-box-img"><img src="/assets/profile/person_one-min.jpg" alt="" className={`img-fluid`} style = {{objectFit: 'contain'}} /></div>
                                    <div className="image-box-content">
                                        <h3 className="image-box-title">
                                            Charlotte Welleman
                                        </h3>
                                        <span className="image-box-description">
                                            +32 498 93 79 36
                                        </span>
                                    </div>
                                </div>
                                <div className="image-box-wrap" style = {{border: 'none'}}>
                                    <div className="image-box-img"><img src="/assets/profile/person_two-min.jpg" alt="" className={`img-fluid`} style = {{objectFit: 'contain'}} /></div>
                                    <div className="image-box-content">
                                        <h3 className="image-box-title">
                                            Pieter-Jan Windey   
                                        </h3>
                                        <span className="image-box-description">
                                            +32 472 80 20 65
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-12"></div>
                            <div className="col-lg-4 col-md-12">
                                <h6 className="heading-title">VERSTUUR EEN BERICHT</h6>
                                <ContactForm id="contactFooter"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Section #2*/}
                {/*Section 3*/}
                <div className="section-3">
                    <div className="container text-center" style = {{borderTop: 'solid 1px rgba(255, 255, 255, 0.2)'}}>
                        <div className="row" style = {{justifyContent: 'space-between', marginTop: '25px'}}>
                            <a href="https://www.gaelensbouwprojecten.be/nl" target="_blank"><Copyright title="GAELENS" message = "© Gaelens Bouwprojecten"/></a>
                            <p className = "copyright-secondary" style = {{textDecoration: 'underline'}}>Made with ❤️ by <a href="https://agifly.be/" target="_blank">Agifly</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


export default withTranslate(FooterOne);
