import React, { Component } from 'react';
import Heading from "../../common/heading/heading";

import "./overview.scss";

class OverviewSection extends Component {
    btnClick() {
        window.open("https://www.google.com/maps/place/Mieregemstraat+21,+1785+Merchtem/@50.9561574,4.2313584,17z/data=!4m5!3m4!1s0x47c3eab606ea6853:0x85c7c6eb635e8396!8m2!3d50.9562577!4d4.2316733");
    }
    render() {
        return (
            <div className="container">
                <div className="row align-items-start" id="PROJECT">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <img style={{ height: "650px", objectFit: 'cover' }} className="img-fluid" src="/assets/imagewebp/theme/gallery4.jpg" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-right">
                        <h3 style={{ color: "#CABD9E", textTransform: 'uppercase' }} className = "overview-header" >Mieregemplein</h3>
                        <h3>Gezellig wonen in Merchtem</h3>
                        <p className="text-black">
                            Residentie Mieregemplein bestaat uit twee gebouwen met één ondergrondse parkeergarage. De ondergrondse verdieping bevindt zich onder de twee gebouwen.
                        </p>
                        <p className="text-black">
                            In gebouw A bevinden zich 6 wooneenheden en in gebouw B bevinden zich 12 wooneenheden. Elk appartement beschikt over een ruim terras.
                        </p>
                        <p className="text-black">
                            In de ondergrondse verdieping bevinden zich 27 parkings, waarvan 3 rolstoeltoegankelijke, 9 garageboxen (6 enkele en 3 dubbele) en 16 fietsparkeerplaatsen voor bewoners.
                        </p>
                        <p className="text-black">
                            De appartementen zijn kwaliteitsvol ingedeeld en afgewerkt en bieden zo een groot comfort aan de bewoners. Het gebruik van duurzame materialen en technieken resulteren in een gegarandeerd laag energieverbruik en maken van de appartementen een uiterst toekomstgerichte investering.
                        </p>
                        <p className="text-black">
                            Het perceel wordt verzorgd groen ingekleed met de nodige aandacht voor de privacy van de bewoners, als ook de omwonenden.
                        </p>
                    </div>
                </div>
                <div className="row mt-5 overview-section-padding" style={{ margin: 0, position: 'relative' }} id="LIGGING">
                    <div className = "overview-second-background" />
                    <div className="col-lg-6 col-md-12 col-sm-12 content-left">
                        <h3 className = "overview-header" style={{ color: "#CABD9E", textTransform: 'uppercase' }}>Ligging en bereikbaarheid</h3>
                        <h3>Wonen nabij het centrum</h3>
                        <p className="text-black">
                            Het project is gelegen nabij het centrum van de gemeente Merchtem met zijn vele handelszaken, supermarkten, sporthal en wekelijkse markt. Verschillende scholen bevinden zich op wandelafstand of fietsafstand van het project.
                        </p>
                        <p className="text-black">
                            Via trein en bus geniet de gemeente Merchtem van een optimale ontsluiting met de vele steden en gemeenten in de buurt en met de hoofdstad Brussel.
                        </p>
                        <p className="text-black">
                            Met de wagen is er een goede aansluiting met de Ring rond Brussel en met de autosnelwegen naar Antwerpen en naar Gent/ Brugge/Oostende.
                        </p>
                        <div className=" ">
                            <button type="button" className="btn btn-outline-primary " onClick={this.btnClick.bind(this)} >VIA GOOGLE ONTDEKKEN</button>

                        </div>

                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12" style = {{padding: '0px'}}>
                        <img style={{ height: "640px", marginTop: "55px", objectFit: 'cover' }} className="img-fluid" src="/assets/imagewebp/theme/gallery3.jpg" alt="" />
                    </div>
                </div>
                <div className="row  align-items-start mt-5" id="DUURZAAMHEID">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <img style={{ height: "620px", objectFit: 'cover' }} className="img-fluid" src="/assets/imagewebp/theme/gallery1.jpg" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-right">
                        <h3 style={{ color: "#CABD9E" }}>Duurzaam wonen</h3>
                        <h3>Duurzaamheid staat centraal:</h3>
                        <ol className="text-black">
                            <li>Doordachte inplanting met maximaal gebruik van zonlicht</li> 
                        </ol>
                        <ul className="text-black" style={{ paddingLeft:"80px" }}>
                            <li style={{ marginBottom:"20px" }}>Zongerichte terrassen en/of tuinen</li>
                            <li>Conform de EPB- reglementeringen</li>
                        </ul>
                        <ol className="text-black" start = "2">
                            <li>Energiezuinig bouwen</li> 
                        </ol>
                        <ul className="text-black" style={{ paddingLeft:"80px" }}>
                            <li style={{ marginBottom:"20px" }}>Optimaal energiezuinig gebouw door de extra aandacht voor isolatie van de buitenwanden en het dak</li>
                            <li style={{ marginBottom:"20px" }}>Er wordt gebruik gemaakt van een individuele geothermische warmtepomp</li>
                            <li>Bovendien wordt beroep gedaan op hernieuwbare energie door het gebruik van zonnepanelen</li>
                        </ul>









                    </div>
                </div>



            </div>

        )
    }
}

export default OverviewSection
